import React, { useState } from "react";
import axios from "axios";

const PdfProtector = () => {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const [protectedPdfUrl, setProtectedPdfUrl] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !password) {
      alert("Please upload a PDF file and set a password.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("password", password);
    debugger;
    try {
      const response = await axios.post(
        "http://localhost:8000/api/v1/pdf/protect-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // important to handle binary data
        }
      );
      debugger;
      const url = URL.createObjectURL(response.data);
      setProtectedPdfUrl(url);
    } catch (error) {
      console.error("There was an error protecting the PDF:", error);
    }
  };

  return (
    <div>
      <h2>Protect Your PDF</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
        <input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button type="submit">Protect PDF</button>
      </form>
      {protectedPdfUrl && (
        <div>
          <h3>Your protected PDF is ready:</h3>
          <a href={protectedPdfUrl} download="protected.pdf">
            Download Protected PDF
          </a>
        </div>
      )}
    </div>
  );
};

export default PdfProtector;
