import React, { useEffect, useRef, useState } from "react";
import { PDFDocument } from "pdf-lib";
import FileSaver from "file-saver";
import { useTheme } from "@mui/material/styles";
import { Button, Typography, CircularProgress, Grid, Fab } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import * as pdfjsLib from "pdfjs-dist";
pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UnlockPdf = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const hiddenFileInputRef = useRef(null);

  useEffect(() => {
    toast.error("Never Try To Upload Password Protected Pdf");
  }, []);

  const handleButtonClick = () => {
    hiddenFileInputRef.current.click();
  };

  const theme = useTheme();
  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const handleFileChange = async (event) => {
    debugger;
    const file = event.target.files[0];
    if (!file || file.type !== "application/pdf") {
      toast.error("Please select a PDF file");
      return;
    }

    // Read the file as an ArrayBuffer
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileArrayBuffer = file;
      setPdfFiles(fileArrayBuffer); // Save the ArrayBuffer instead of URL
    };
    reader.readAsArrayBuffer(file);
  };

  const handleMerge = async () => {
    debugger;
    setIsLoading(true);
    setErrorMessage("");

    try {
      debugger;
      // Load the PDF with the password
      const pdfDoc = await PDFDocument.create();

      const donorPdfDoc = await PDFDocument.load(pdfFiles, {
        password: "938010110004861", // Convert password to string if necessary
      });

      const pages = await pdfDoc.copyPages(
        donorPdfDoc,
        donorPdfDoc.getPageIndices()
      );
      pages.forEach((page) => {
        pdfDoc.addPage(page);
      });

      // Remove the password
      pdfDoc.encrypt({});

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

      FileSaver.saveAs(pdfBlob, "merged.pdf");
    } catch (error) {
      setErrorMessage(`Error merging PDFs: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Grid item>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          style={truncatedText}
        >
          Upload Pdfs
          <VisuallyHiddenInput
            multiple
            accept="application/pdf"
            type="file"
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={handleMerge}
          style={truncatedText}
          disabled={pdfFiles.length === 0 || isLoading}
          sx={{ marginBottom: 2 }}
        >
          Merge PDFs
        </Button>
      </Grid>

      {isLoading && <CircularProgress />}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      <iframe
        title={pdfFiles.name}
        src={pdfFiles}
        style={{ width: "100%", height: "200px" }}
      />

      <ToastContainer position="top-right" autoClose={2000} />
    </Grid>
  );
};

export default UnlockPdf;
