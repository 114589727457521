import Home from "../components/Home";
import ImageReducer from "../components/ImageReducer";
import ImageToPdf from "../components/ImageToPdf";
import PDFCompressor from "../components/PdfCompressor";
import PdfMerger from "../components/PdfMerger";
import PdfProtector from "../components/PdfProtector";
import PdfSplitter from "../components/PdfSplitter";
import PdfToImage from "../components/PdfToImage";
import PdfToWord from "../components/PdfToWord";
import UnlockPdf from "../components/UnlockPdf";

export const route = [
  {
    Component: Home,
    title: "/",
  },
  {
    Component: ImageReducer,
    title: "/imageReducer",
  },
  {
    Component: PDFCompressor,
    title: "/PDFCompressor",
  },
  {
    Component: ImageToPdf,
    title: "/imageToPdf",
  },
  {
    Component: PdfToImage,
    title: "/pdfToImage",
  },
  {
    Component: PdfToWord,
    title: "/pdfToWord",
  },
  {
    Component: PdfMerger,
    title: "/mergePdf",
  },
  {
    Component: UnlockPdf,
    title: "/unlockPdf",
  },
  {
    Component: PdfProtector,
    title: "/pdfProtector",
  },
  {
    Component: PdfSplitter,
    title: "/pdfSplitter",
  },
];
