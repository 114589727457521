import React, { useEffect, useRef, useState } from "react";
import { PDFDocument } from "pdf-lib";
import FileSaver from "file-saver";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Fab,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { toast, ToastContainer } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import PdfComp from "./PdfComp";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function PdfMerger() {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const hiddenFileInputRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    toast.error("Never Try To Upload Password Protected Pdf");
  }, []);

  const handleButtonClick = () => {
    hiddenFileInputRef.current.click();
  };

  const theme = useTheme();
  const truncatedText = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "40px",
    width: "200px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
  };

  const handleFileChange = async (event) => {
    debugger;
    if (event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const newPdfFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const arrayBuffer = await file.arrayBuffer();

        const fileURL = URL.createObjectURL(file); // Create a URL for each file

        // Store necessary data
        newPdfFiles.push({
          id: `${file.name}-${i}`, // Unique ID for drag-and-drop
          name: file.name,
          arrayBuffer,
          url: fileURL,
        });
      }

      setPdfFiles(newPdfFiles);
    }
  };

  const handleAddNewFile = async (event) => {
    if (event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const newPdfFiles = [...pdfFiles];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const arrayBuffer = await file.arrayBuffer();
        const fileURL = URL.createObjectURL(file); // Create a URL for each file

        // Store necessary data
        newPdfFiles.push({
          id: `${file.name}-${newPdfFiles.length}`, // Unique ID for drag-and-drop
          name: file.name,
          arrayBuffer,
          url: fileURL,
        });
      }

      setPdfFiles(newPdfFiles);
      hiddenFileInputRef.current.value = null;
    }
  };

  const handleMerge = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      const pdfDoc = await PDFDocument.create();

      for (const pdfFile of pdfFiles) {
        const donorPdfDoc = await PDFDocument.load(pdfFile.arrayBuffer, {
          ignoreEncryption: true,
        });
        const pages = await pdfDoc.copyPages(
          donorPdfDoc,
          donorPdfDoc.getPageIndices()
        );
        pages.forEach((page) => {
          pdfDoc.addPage(page);
        });
      }

      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

      FileSaver.saveAs(pdfBlob, "merged.pdf");
    } catch (error) {
      setErrorMessage(`Error merging PDFs: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = (result) => {
    // Remove debugger statement
    if (!result.destination) return;
    const reorderedFiles = Array.from(pdfFiles);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);
    setPdfFiles(reorderedFiles);

    // Force a re-render by triggering an additional state update
    setTimeout(() => setPdfFiles([...reorderedFiles]), 0);
  };

  const handleDeleteTask = (id) => {
    const arr = pdfFiles.filter((task) => task.id !== id);

    if (arr.length === 0) {
      fileInputRef.current.value = null;
      setPdfFiles([]);
    } else {
      setPdfFiles(arr);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            backgroundColor: "#7acc91",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          Merge PDF
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Grid item>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            style={truncatedText}
          >
            {pdfFiles.length === 0 ? "Upload Pdfs" : "Reupload Pdfs"}
            <VisuallyHiddenInput
              multiple
              accept="application/pdf"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleMerge}
            style={truncatedText}
            disabled={pdfFiles.length === 0 || isLoading}
            sx={{ marginBottom: 2 }}
          >
            Merge PDFs
          </Button>
        </Grid>
      </Grid>

      {isLoading && <CircularProgress />}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={["droppable"]}>
          {(provided) => (
            <Grid
              container
              spacing={2}
              style={{
                width: "70%",
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {pdfFiles.map((file, index) => {
                return (
                  <Draggable
                    key={file.id}
                    draggableId={file.id}
                    index={index}
                    shouldRespectForcePress={true}
                  >
                    {(provided) => (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Card
                          sx={{
                            paddingBottom: "30px",
                          }}
                        >
                          <CardContent sx={{ position: "relative" }}>
                            <CancelIcon
                              sx={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                zIndex: 1,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => handleDeleteTask(file.id)}
                            />
                            <PdfComp docUrl={file.url} />
                            <div {...provided.dragHandleProps}></div>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      {pdfFiles.length !== 0 && (
        <Fab
          style={{ position: "fixed", bottom: "70px", right: "10px" }}
          size="small"
          color="secondary"
          aria-label="add"
          onClick={handleButtonClick}
        >
          <AddIcon />
          <VisuallyHiddenInput
            ref={hiddenFileInputRef}
            type="file"
            multiple
            accept="application/pdf"
            onChange={handleAddNewFile}
          />
        </Fab>
      )}
      <ToastContainer position="top-right" autoClose={2000} />
    </Grid>
  );
}

export default PdfMerger;
